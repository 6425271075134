
import { message, Empty } from 'ant-design-vue'
import { deleteEncAb, getTagList, postEnc, encAb, encResourse, bindEncDetail, editEnc } from '@/api/index'
import { computed, defineComponent, onMounted, reactive, ref, toRaw } from 'vue'
import vueJsonEditors from 'vue-json-editor'
import uploadAb from './upload-ab.vue'
import { ValidateErrorEntity } from 'ant-design-vue/es/form/interface'
import { SEND_TYPE, OSSURL, OSS_ICON } from '@/hooks/ali-oss'
import { filterKeys } from '@/hooks/common-hooks'
import { RightOutlined, DownOutlined } from '@ant-design/icons-vue'
import draggable from 'vuedraggable'
import { useRouter } from 'vue-router'
interface FormState {
    name: string;
    remarks: string;
    description: string;
    tags: number[];
    icon_path: string;
    source: number;
    netpad_url: string;
}
export interface FilesProps {
  file_name: string;
  file_path: string;
  [key: string]: any;
}
export interface FileProps {
  file?: FilesProps;
  file_name?: string;
  file_path?: string;
  file_size?: string;
  file_type?: string;
  url?: string;
  [key: string]: any;
}
interface ReauestProps {
  type: string;
  file_name: string;
  file_path: string;
}
export default defineComponent({
  name: 'CreateEnc',
  props: ['id'],
  components: {
    vueJsonEditors,
    // uploadImage,
    uploadAb,
    // draggable,
    RightOutlined,
    DownOutlined
  },
  setup (props, context) {
    const upAbwList = ref([])
    const upAbaList = ref([])
    const upAbiList = ref([])

    const router = useRouter()
    const abwIsOpen = ref(false)
    const openAbwContent = () => {
      abwIsOpen.value = !abwIsOpen.value
    }
    const abaIsOpen = ref(false)
    const openAbaContent = () => {
      abaIsOpen.value = !abaIsOpen.value
    }
    const abiIsOpen = ref(false)
    const openAbiContent = () => {
      abiIsOpen.value = !abiIsOpen.value
    }
    const init = ref({})
    const encyclopedia_id = ref(null)
    const listIcon: FileProps = ref([]) // 封面
    const upObj = ref([SEND_TYPE[1]])
    const changeFileIcon = (lists: never[]) => {
      console.log(lists, 'icon')
      listIcon.value = lists
    }
    const resetListIcon = () => {
      listIcon.value.pop()
    }
    const iconUrl = computed(() => {
      if (listIcon.value.length === 0) {
        return ''
      } else {
        return (listIcon.value[0] as FileProps).file_path
      }
    })
    // ab包
    const abwList = ref([])
    const abwUpObj = ref([SEND_TYPE[5]])

    const abaList = ref([])
    const abaUpObj = ref([SEND_TYPE[6]])
    const changeFile = (lists: any, oladList: any, upLoadList: any) => {
      const isUploaded = oladList.every((item: any) => Object.prototype.hasOwnProperty.call(item, 'id'))
      if (isUploaded) {
        oladList.unshift(...lists)
      } else {
        oladList.splice(0, 1, ...lists)
      }
      upLoadList = lists
      console.log(upLoadList, '123')
    }

    const abiList = ref([])
    const abiUpObj = ref([SEND_TYPE[7]])

    const encId = computed(() => { return props.id })
    // 表单
    const formRef = ref()
    const formState: FormState = reactive({
      name: '',
      remarks: '',
      description: '',
      tags: [],
      icon_path: '',
      source: 1,
      netpad_url: ''
    })
    const rules = {
      name: [
        { required: true, message: '请输入百科名称', trigger: 'blur', whitespace: true }
      ],
      remarks: [
        { required: true, message: '请输入百科备注', trigger: 'blur', whitespace: true }
      ],
      tags: [
        { required: true, message: '请选择百科标签', trigger: 'change', type: 'array' }
      ],
      netpad_url: [
        { required: true, message: '请输入网络地址', trigger: 'blur', whitespace: true }
      ]
    }
    // 添加百科
    const addEncHttp = (parm: object, callback?: () => void) => {
      postEnc(Object.assign(formState, parm)).then(res => {
        encyclopedia_id.value = res.data
        message.success('创建百科成功')
        context.emit('changeIndex', 0)
        if (callback) {
          callback()
        }
      })
    }
    // 修改百科
    const editEncHttp = (parm: object, callback?: () => void) => {
      editEnc(Object.assign(formState, parm, { id: props.id })).then(res => {
        message.success('百科修改成功')
        router.push({ name: 'EncyclopediasManger' })
        if (callback) {
          callback()
        }
      })
    }

    const uploadIcon = ref()
    const uploadAbw = ref()
    const uploadAbi = ref()
    const uploadAba = ref()
    // 添加ab包
    const postEncAb = (list: ReauestProps[]) => {
      if (list.length > 0) {
        encAb({
          encyclopedia_id: props.id ? props.id : encyclopedia_id.value,
          resources: list
        }).then(res => {
          console.log(res)
        })
      }
    }
    // 重置list值
    const resetList = (lists: FileProps) => {
      return lists.map((ele: FileProps) => {
        return {
          type: ele.file_type,
          file_name: ele.file_name,
          file_path: ele.file_path
        }
      })
    }
    const namesFun = async (name: any) => {
      // if (iconUrl.value) {
      //   formState.icon_path = iconUrl.value
      //   uploadIcon.value.fetchSend(name({}))
      // } else {
      //   name({})
      // }
      if (JSON.stringify(init.value) !== '{}' && upAbwList.value.length === 0 && upAbiList.value.length === 0 && upAbaList.value.length === 0) {
        name({
          init: JSON.stringify(init.value)
        })
      } else {
        if (upAbwList.value.length) {
          await uploadAbw.value.fetchSend()
        }
        if (upAbiList.value.length) {
          await uploadAbi.value.fetchSend()
        }
        if (upAbaList.value.length) {
          await uploadAba.value.fetchSend()
        }
        name({
          init: JSON.stringify(init.value)
        }, () => {
          postEncAb(resetList(upAbwList.value.concat(upAbiList.value, upAbaList.value)))
        })
      }
    }
    // 提交表单
    const saveForm = () => {
      formRef.value.validate().then(() => {
        if (props.id) {
          // 修改百科
          namesFun(editEncHttp)
        } else {
          // 创建百科
          namesFun(addEncHttp)
        }
      }).catch((error: ValidateErrorEntity<FormState>) => {
        console.log('error', error)
      })
    }

    // 获取百科标签
    const tagList = ref([])
    const fetchTag = () => {
      getTagList({}).then(res => {
        tagList.value = res.data
      })
    }

    // 获取百科详情
    const getEncDetail = () => {
      bindEncDetail({
        id: props.id
      }).then(res => {
        filterKeys(formState, res.data)
        formState.tags = res.data.tag_ids ? res.data.tag_ids.split(',').map(Number) : []
        if (res.data.icon_path) {
          const _item1 = {
            url: OSSURL + '/' + res.data.icon_path
          }
          listIcon.value = [_item1]
        }
        init.value = JSON.parse(res.data.init)
        if (res.data.ab_resource) {
          abwList.value = res.data.ab_resource.filter((ele: any) => {
            return ele.type === 1
          })
          abaList.value = res.data.ab_resource.filter((ele: any) => {
            return ele.type === 2
          })
          abiList.value = res.data.ab_resource.filter((ele: any) => {
            return ele.type === 3
          })
        }
      })
    }
    // 删除文件
    const deleteFile = (list: FileProps, item: number) => {
      const _index = list.findIndex((ele: any) => ele === item)
      list.splice(_index, 1)
    }
    const handleChange = (value: string[]) => {
      console.log(`selected ${value}`)
    }
    const onJsonChange = (value: object) => {
      console.log('value1:', value)
      init.value = value
    }

    const getVideoUrl = (item: FileProps) => {
      const path = item.file_path ? item.file_path : item.file_path
      return OSSURL + '/' + path
    }
    // 复制下载地址
    const copyPath = (shareLink: any) => {
      const input = document.createElement('input')
      input.value = shareLink
      document.body.appendChild(input)
      input.select()
      document.execCommand('Copy')
      document.body.removeChild(input)
      message.success('复制成功')
    }
    // 删除数据库ab包数据
    const deleteAb = (id: number) => {
      deleteEncAb({
        id: id
      }).then(res => {
        message.success('删除成功')
        getEncDetail()
      })
    }
    const handleDelete = (index: number, oladList: any, upLoadList: any) => {
      const id = oladList[index].id
      if (id) {
        deleteAb(id)
      }
      oladList.splice(index, 1)
      upLoadList.splice(0, 1)
    }
    onMounted(() => {
      fetchTag()
      if (props.id) {
        getEncDetail()
      }
    })
    return {
      OSS_ICON,
      getVideoUrl,
      deleteFile,
      init,
      OSSURL,
      uploadAba,
      uploadAbi,
      uploadAbw,
      uploadIcon,
      ABactiveKey: ref('abw'),
      activeKey: ref('1'),
      abiUpObj,
      abiList,
      abaUpObj,
      abaList,
      abwUpObj,
      abwList,
      listIcon,
      upObj,
      changeFileIcon,
      resetListIcon,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      handleChange,
      formRef,
      formState,
      rules,
      saveForm,
      tagList,
      onJsonChange,
      encId,
      // addType,
      abwIsOpen,
      openAbwContent,
      abaIsOpen,
      openAbaContent,
      abiIsOpen,
      openAbiContent,
      copyPath,
      handleDelete,
      changeFile,
      upAbwList,
      upAbaList,
      upAbiList
    }
  }
})
